import React, { useContext } from 'react';

import './Header.scss';
import { AppCtx } from 'context/appContext';
import { isGuest } from 'utils';
import { TOKEN_NAME } from '../../consts';
import { Button } from '@mui/material';
import logo from 'assets/img/logo.png';

const Header = () => {
  const {
    valueProps: { userName },
  } = useContext(AppCtx);
  return (
    <div className='Header'>
      <img height={33} src={logo} alt='Logo' />
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => (document.location = 'https://konigsland.ru')}
      >
        Königsland.<div className='rotate'>online</div>
      </span>
      {!isGuest(userName) && (
        <div className='logout'>
          <Button
            size='small'
            variant='contained'
            onClick={() => {
              localStorage.removeItem(TOKEN_NAME);
              document.location = '/';
            }}
          >
            Выход
          </Button>
        </div>
      )}
    </div>
  );
};

export default Header;
