import React, { useContext, useEffect } from 'react';
import Auth from 'components/Auth/Auth';
import './SideBar.scss';
import {
  sendGetMarkersRequest,
  sendGetMarkerTypesRequest,
  sendGetUserRequest,
  sendWithdrawalRequest,
} from 'api';
import { AppCtx } from 'context/appContext';
import { isGuest } from 'utils';
import { token } from 'consts';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { LatLng } from 'leaflet';
import debounce from 'lodash/debounce';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const SideBar = () => {
  const {
    valueProps: { userName, balance, currentPosition, map, currentTile },
    setProps: {
      setCurrentPosition,
      setUserName,
      setBalance,
      setMarkers,
      setMarkerImages,
      setMarkerTypes,
      setBlogPostsUris,
      setCurrentTile,
    },
  } = useContext(AppCtx);

  useEffect(() => {
    token && sendGetUserRequest(token, setUserName, setBalance);
    sendGetMarkersRequest(setMarkers, setMarkerImages, setBlogPostsUris);
    sendGetMarkerTypesRequest(setMarkerTypes);
  }, [
    setMarkers,
    setUserName,
    setMarkerImages,
    setMarkerTypes,
    setBlogPostsUris,
    setBalance,
  ]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    textAlign: 'center',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState('0.000100');
  const [wallet, setWallet] = React.useState('');
  const [responseText, setResponseText] = React.useState('');
  const [responseStatus, setResponseStatus] = React.useState(400);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const debouncedMoveToCoordinates = debounce((newPosition) => {
    map?.flyTo(newPosition, 15);
  }, 3000);

  const onLatChange = (e) => {
    try {
      const newPosition = new LatLng(
        parseFloat(e.target.value),
        currentPosition.lng
      );
      setCurrentPosition(newPosition);
      debouncedMoveToCoordinates(newPosition);
    } catch (error) {
      console.log(error);
    }
  };

  const onLngChange = (e) => {
    try {
      const newPosition = new LatLng(
        currentPosition.lat,
        parseFloat(e.target.value)
      );
      setCurrentPosition(newPosition);
      debouncedMoveToCoordinates(newPosition);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setCurrentTile(event.target.value);
  };

  const submitWithdrawal = () => {
    setResponseText('');
    setResponseStatus(200);
    if (token) {
      if (amount) {
        if (wallet) {
          sendWithdrawalRequest(
            token,
            amount,
            wallet,
            setResponseText,
            setResponseStatus,
            setOpen
          );
        } else {
          setResponseStatus(400);
          setResponseText('Вы не указали кошелек');
        }
      } else {
        setResponseStatus(400);
        setResponseText('Вы не ввели сумму');
      }
    }
  };
  const title =
    'За добавление находок вы получаете по 0.00001 BTC сразу после того, как позиция проходит модерацию и попадает на оценку в канал https://t.me/konigsland';
  return (
    <div className='SideBar'>
      <div className='control'>
        <FormControl
          style={{
            marginTop: '-19px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 155,
            height: 200,
            padding: 10,
            margin: 10,
          }}
        >
          <RadioGroup
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={currentTile}
            onChange={handleChange}
          >
            <FormControlLabel value='Схема' control={<Radio />} label='Схема' />
            <FormControlLabel
              value='Схема2'
              control={<Radio />}
              label='Схема2'
            />
            <FormControlLabel
              value='Пруссия'
              control={<Radio />}
              label='Пруссия'
            />
            <FormControlLabel
              value='ArcGIS'
              control={<Radio />}
              label='ArcGIS'
            />
            <FormControlLabel
              value='Google'
              control={<Radio />}
              label='Google'
            />
          </RadioGroup>
        </FormControl>
        <div className='coordinates'>
          <TextField
            id='lat'
            onChange={onLatChange}
            type='number'
            color='success'
            size='small'
            value={currentPosition.lat}
            label='Широта'
            variant='outlined'
            inputProps={{
              maxLength: 13,
              step: '0.0001',
            }}
          />
          <TextField
            id='lng'
            onChange={onLngChange}
            type='number'
            color='success'
            size='small'
            value={currentPosition.lng}
            label='Долгота'
            variant='outlined'
            inputProps={{
              maxLength: 13,
              step: '0.0001',
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        {token && (
          <>
            <Tooltip title={title}>
              <span style={{ color: 'darkseagreen', cursor: 'pointer' }}>
                {!isGuest(userName) && balance} BTC
              </span>
            </Tooltip>
            <Button onClick={handleOpen}>Вывод</Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={style}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Вывод от <b>0.0001 BTC</b>
                </Typography>
                <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                  <TextField
                    required
                    id='amount'
                    name='amount'
                    type='number'
                    value={amount}
                    variant='outlined'
                    inputProps={{
                      step: 0.00001,
                      min: 0.0001,
                    }}
                    label='Сумма'
                    onChange={(e) =>
                      setAmount(
                        parseFloat(e.target.value).toFixed(6) || '0.0001'
                      )
                    }
                  />
                  <br />
                  <br />
                  <TextField
                    required
                    id='wallet'
                    name='wallet'
                    value={wallet}
                    label='Кошелек'
                    defaultValue=''
                    onChange={(e) => setWallet(e.target.value)}
                  />
                  <br />
                  <div
                    style={{
                      marginTop: 15,
                      color: responseStatus === 200 ? 'green' : 'orangered',
                    }}
                  >
                    {responseText && responseStatus && `${responseText}`}
                  </div>
                  <br />
                  <Button
                    size='small'
                    onClick={submitWithdrawal}
                    variant='contained'
                    color='success'
                  >
                    Вывести
                  </Button>
                </Typography>
              </Box>
            </Modal>
          </>
        )}
        <br />
        <br />
      </div>
      {!isGuest(userName) && (
        <div
          style={{
            color: 'sandybrown',
            cursor: 'pointer',
            width: 300,
            marginBottom: 20,
            fontSize: '9pt',
          }}
        >
          <Accordion
            style={{
              backgroundColor: 'darkseagreen',
              boxShadow: 'inset 0.1em 0.1em silver',
            }}
          >
            <AccordionSummary
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>{userName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{ textAlign: 'left', marginTop: '-20px' }}>
                <li>Находка: 0.00001 BTC</li>
                <li>Территория: 0.000005 BTC</li>
                <li>Реферал: 0.000015 BTC</li>
              </ul>
              <Typography style={{ fontSize: '10pt' }}>
                Реферальная ссылка:
                <br />
                <a href='https://konigsland.online/?start=123'>
                  https://konigsland.online/?start={userName}
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      {!token && <Auth />}
    </div>
  );
};

export default SideBar;
