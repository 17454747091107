import { BOT_NAME } from 'consts';
import React, { useEffect } from 'react';

export const AuthForm = () => {
  // to auth from localhost we should manually set token
  useEffect(() => {
    const content = document.getElementById('content');
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?15';
    script.async = true;
    script.setAttribute('data-telegram-login', BOT_NAME);
    script.setAttribute('data-size', 'medium');
    script.setAttribute('data-auth-url', 'http://konigsland.ru/login/');
    content?.appendChild(script);
  }, []);

  return (
    <div className='form'>
      <div id='content'></div>
    </div>
  );
};
