import axios, { AxiosResponse } from 'axios';
import { API_URL, token, TOKEN_NAME } from 'consts';
import assert from 'assert';
import { Dispatch } from 'react';
import { LatLng } from 'leaflet';
import { MarkerType, MyMarker } from './context/appContext';

export const apiClient = () => {
  const params = {
    baseURL: API_URL,
  };
  const finalParams = token
    ? { ...params, headers: { Authorization: 'Token ' + token } }
    : params;
  return axios.create(finalParams);
};

const apiMap = {
  reg: 'users',
  auth: 'login',
  user: 'user',
  withdrawal: 'withdrawal',
  marker: 'markers',
  type: 'types',
};

const getFullApiUrl = (service: string, path: string = '') => {
  const serviceUri = `${API_URL}/${service}`;
  return !path ? `${serviceUri}/` : `${serviceUri}/${path}/`;
};

const checkError = (response: AxiosResponse<any>) => {
  const { data } = response;
  try {
    const status = data.status;
    // alert(status)
    assert(status === 'ok');
    return false;
  } catch (error) {
    const err = error as Error;
    const { name, message } = err;
    const result = name === 'AssertionError' ? data.message : message;
    // console.error(error);
    return result;
  }
};

export const sendWithdrawalRequest = (
  token: string,
  amount: string,
  wallet: string,
  setState: Dispatch<string>,
  setState2: Dispatch<number>,
  setState3: Dispatch<boolean>
) => {
  const requestData = JSON.stringify({ token, amount, wallet });
  const fullUrl = getFullApiUrl(apiMap.withdrawal, token);
  axios
    .post(fullUrl, requestData)
    .then((res: any) => {
      const error = checkError(res);
      if (error) {
        alert(error);
        localStorage.removeItem(TOKEN_NAME);
      } else {
        const data = res.data;
        setState(data.text);
        setState2(data.status);
        if (data.status === 200) {
          setState3(false);
        }
      }
    })
    .catch((e) => alert(e));
};

export const sendGetUserRequest = (
  token: string,
  setState: Dispatch<string>,
  setState2: Dispatch<number>
) => {
  const requestData = JSON.stringify({ token });
  const fullUrl = getFullApiUrl(apiMap.user, token);
  axios
    .post(fullUrl, requestData)
    .then((res: any) => {
      const error = checkError(res);
      if (error) {
        alert(error);
        localStorage.removeItem(TOKEN_NAME);
      } else {
        const data = res.data;
        const userName = data.username;
        const balance = data.balance;
        setState(userName);
        setState2(balance);
      }
    })
    .catch((e) => alert(e));
};

export const sendCreateMarkerRequest = (
  { id: markerTypeId }: MarkerType,
  position: LatLng,
  description: string,
  markers: Array<MyMarker>,
  files: File[],
  setMarkers: Dispatch<Array<MyMarker>>
) => {
  let requestData = new FormData();
  files.forEach((f, ind) => requestData.append(`img${ind}`, f));
  requestData.append('lat', `${position.lat}`);
  requestData.append('lng', `${position.lng}`);
  requestData.append('description', description);
  requestData.append('marker_type_id', `${markerTypeId}`);
  const fullUrl = getFullApiUrl(apiMap.marker);
  apiClient()
    .post(fullUrl, requestData)
    .then((res: any) => {
      const error = checkError(res);
      if (error) {
        alert(error);
      } else {
        setMarkers([...markers, res.data]);
        document.location = '/';
      }
    });
};

export const sendGetMarkersRequest = (
  setMarkers: Dispatch<Array<MyMarker>>,
  setMarkerImages: Dispatch<{ id: object }>,
  setBlogPostsUris: Dispatch<{ id: object }>
) => {
  const fullUrl = getFullApiUrl(apiMap.marker);
  apiClient()
    .get(fullUrl)
    .then((res: any) => {
      const error = checkError(res);
      if (error) {
        alert(error);
      } else {
        const markerList = res.data.data.map((m: MyMarker) => m);
        const markerImages = res.data.images;
        const blogPostsUris = res.data.blog_posts;
        setMarkers(markerList);
        setMarkerImages(markerImages);
        setBlogPostsUris(blogPostsUris);
      }
    });
};

export const sendGetMarkerTypesRequest = (
  setMarkerTypes: Dispatch<Array<MarkerType>>
) => {
  const fullUrl = getFullApiUrl(apiMap.type);
  apiClient()
    .get(fullUrl)
    .then((res: any) => {
      const error = checkError(res);
      if (error) {
        alert(error);
      } else {
        const markerTypesList = res.data?.map((mt: MarkerType) => mt);
        setMarkerTypes(markerTypesList);
      }
    });
};
