import { AuthForm } from "forms/AuthForm";

import "./Auth.scss";

const Auth = () => {

  return (
    <div className="Auth">
      <AuthForm />
    </div>
  );
};

export default Auth;
