import React from 'react';
import Map from 'components/Map/Map';
import SideBar from 'components/SideBar/SideBar';

import './Content.scss';
import Slider from './Slider/Slider';

const Content = () => (
  <div id='Content' className='Content'>
    <Map />
    <SideBar />
  </div>
);

export default Content;
