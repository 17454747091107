import { MarkerType, MyMarker } from 'context/appContext';
import { Marker, Popup } from 'react-leaflet';
import { token } from 'consts';
import * as L from 'leaflet';
import React, { Dispatch, SetStateAction } from 'react';
import { getMarkerEventsHandlers } from 'components/Marker/events';
import { LatLng } from 'leaflet';
import Dropzone from 'react-dropzone';
import { Button } from '@mui/material';

export const getPopup = (
  name: string,
  marker: MyMarker,
  currentMarkerImages: [],
  currentMarkerBlogPostsUri: ''
) => {
  const images =
    currentMarkerImages &&
    currentMarkerImages.map((mi: any) => {
      const imgObject = JSON.parse(mi).image.replace(
        'marker/static',
        'media/marker/static'
      );
      const src = `https://konigsland.ru/${imgObject}`;
      const imgMargin = { marginTop: 10 };
      const key = `popup-${marker.id}`;

      return (
        <img
          onClick={(event) => window.open(src, '_blank')}
          key={key}
          style={imgMargin}
          src={src}
          alt='img'
        />
      );
    });

  return (
    <Popup>
      <div className='marker-info'>
        <b style={{ color: 'sandybrown' }}>{name}</b>
        <div>
          <a
            href={`https://konigsland.online/?lat=${marker.lat}&lng=${marker.lng}`}
          >
            https://konigsland.online/?lat={marker.lat}&lng={marker.lng}
          </a>
        </div>
        <span>Широта: {marker.lat}</span>
        <span>Долгота: {marker.lng}</span>
        <hr />
        <div className='images'>{images}</div>
        <span className='description'>{marker.description}</span>
        <div style={{ textAlign: 'right' }}>
          {currentMarkerBlogPostsUri && (
            <a
              rel='noreferrer'
              target='_blank'
              href={currentMarkerBlogPostsUri}
            >
              Подробно
            </a>
          )}
          {name === 'Продажа' && (
            <Button size='small' variant='contained'>
              Купить
            </Button>
          )}
        </div>
      </div>
    </Popup>
  );
};

export const getCurrentMarkerImages = (
  marker: MyMarker,
  markerImages: object | undefined
) => {
  let currentMarkerImages;
  for (const key in markerImages) {
    if (markerImages.hasOwnProperty(key)) {
      if (parseInt(key) === marker.id) {
        // @ts-ignore
        currentMarkerImages = markerImages[parseInt(key)];
      }
    }
  }
  return currentMarkerImages;
};

export const getCurrentMarkerBlogPost = (
  marker: MyMarker,
  blogPostsUris: object | undefined
) => {
  let currentMarkerBlogPost;
  for (const key in blogPostsUris) {
    if (blogPostsUris.hasOwnProperty(key)) {
      if (parseInt(key) === marker.id) {
        // @ts-ignore
        currentMarkerBlogPost = blogPostsUris[parseInt(key)];
      }
    }
  }
  return currentMarkerBlogPost;
};

export const getMarker = (
  markerTypes: MarkerType[],
  marker: MyMarker,
  coordinates: LatLng,
  currentMap: L.Map | undefined,
  currentMarkerImages: [],
  currentMarkerBlogPostsUri: ''
) => {
  return (
    <Marker
      key={`${marker.id}`}
      position={coordinates}
      icon={L.divIcon({
        className: 'marker',
        html: markerTypes.find((mt) => mt.id === marker.marker_type)?.icon,
      })}
      eventHandlers={getMarkerEventsHandlers(currentMap, coordinates)}
    >
      {getPopup(
        markerTypes.find((mt) => mt.id === marker.marker_type)?.type || '',
        marker,
        currentMarkerImages,
        currentMarkerBlogPostsUri
      )}
    </Marker>
  );
};

export const getFileUploadingSection = (
  files: Array<File>,
  setFiles: Dispatch<SetStateAction<Array<File>>>
) =>
  (!files || !files.length) && token ? (
    <Dropzone
      onDrop={(acceptedFiles) => {
        if (acceptedFiles.length > 0 && acceptedFiles.length <= 5) {
          setFiles([...acceptedFiles]);
        } else {
          alert('Можно загруэать от 1 до 5 картинок');
        }
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section className='dropzone'>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Загрузите фотографии (1-5 шт.)</p>
          </div>
        </section>
      )}
    </Dropzone>
  ) : (
    <div className='preview'>
      {files.map((f: File) => (
        <div
          className='delete-image'
          onClick={() => setFiles(files.filter((i) => i !== f))}
        >
          <img src={URL.createObjectURL(f)} alt='preview' />
        </div>
      ))}
    </div>
  );
