import {LatLng, LeafletMouseEvent} from "leaflet";
import $ from "jquery";
import * as L from 'leaflet';

export const mouseMoveEvents = {
    mouseover: (e: LeafletMouseEvent) => {
        $('.leaflet-container').css('cursor', 'pointer')
    },
    mouseout: (e: LeafletMouseEvent) => {
        $('.leaflet-container').css('cursor', 'grab');
    }
}

export const getMarkerEventsHandlers = (currentMap: L.Map|undefined, coordinates: LatLng) => ({
                        click: (e: LeafletMouseEvent) => {
                            currentMap && currentMap.flyTo({lat: coordinates.lat + 0.004, lng: coordinates.lng}, 15);
                        },
                        ...mouseMoveEvents,
                    });
