import React, { useState, useEffect } from "react";
import * as L from 'leaflet';
import Header from "components/Header/Header";
import Content from "components/Content/Content";

import "./App.scss";
import { guestUser, initialPosition } from "consts";
import {AppCtx, MarkerType, MyMarker} from "context/appContext";
import { TOKEN_NAME} from "consts";
const App = () => {
  
  const [userName, setUserName] = useState(guestUser);
  const [balance, setBalance] = useState(0);
  const [showLogin, setShowLogin] = useState(true);
  const [showRegister, setShowRegister] = useState(false);
  const [showPlaceModal, setShowPlaceModal] = useState(false);
  const [showInfo, setShowInfo] = useState(!showLogin && !showRegister);
  const [currentPosition, setCurrentPosition] = useState(initialPosition);
  const [markers, setMarkers] = useState<Array<MyMarker>>([]);
  const [markerTypes, setMarkerTypes] = useState<Array<MarkerType>>([]);
  const [currentTile, setCurrentTile] = useState('Схема');
  const [map, setMap] = useState<L.Map|undefined>(undefined);

  const [files, setFiles] = useState<[]>([]);
  const [markerImages, setMarkerImages] = useState<object|undefined>();
  const [blogPostsUris, setBlogPostsUris] = useState<object|undefined>();

  const valueProps = {
    userName,
    balance,
    showLogin,
    showRegister,
    showPlaceModal,
    showInfo,
    currentPosition,
    markers,
    map,
    files,
    markerImages,
    blogPostsUris,
    markerTypes,
    currentTile
  };
  const setProps = {
    setUserName,
    setBalance,
    setShowLogin,
    setShowRegister,
    setShowPlaceModal,
    setShowInfo,
    setCurrentPosition,
    setMarkers,
    setMap,
    setFiles,
    setMarkerImages,
    setBlogPostsUris,
    setMarkerTypes,
    setCurrentTile
  };
  useEffect(() => {
    const query = new URLSearchParams(document.location.search);
    const token = query.get('t')
    const lat = query.get('lat')
    const lng = query.get('lng')
    if(token){
      localStorage.setItem(TOKEN_NAME, token);
      document.location = "/";
    }else{
      if(lat && lng){
        const marker = markers.find((m) => `${m.lat}` === lat && `${m.lng}` === lng);
        if(marker && map){
          map.flyTo(marker, 14);
        }
        // marker && map?.flyTo(marker, 17);
      }
    }

  }, [map, markers, setMarkers])

  return (
    <div className="App">
      <AppCtx.Provider
        value={{
          setProps: setProps,
          valueProps: valueProps,
        }}
      >
        <Header />
        <Content />
      </AppCtx.Provider>
    </div>
  );
};

export default App;
