import L from 'leaflet';
import existMarkerImage from 'assets/icons/marker-exist.svg';
import markerImage from 'assets/icons/marker.svg';
import garbageImage from 'assets/icons/garbage.svg';

export const garbageIcon = new L.Icon({
  iconUrl: garbageImage,
  iconSize: new L.Point(40, 50),
});

export const markerIcon = new L.Icon({
  iconUrl: markerImage,
  iconSize: new L.Point(40, 50),
});

export const existMarkerIcon = new L.Icon({
  iconUrl: existMarkerImage,
  iconSize: new L.Point(40, 50),
});

export const getMarkerIcon = (url: string) =>
  new L.Icon({
    iconUrl: url,
    iconSize: new L.Point(40, 50),
  });
