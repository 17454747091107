import React, { useContext, useEffect } from 'react';
import { LayersControl, MapContainer, TileLayer } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
import { AppCtx, MyMarker } from 'context/appContext';
import { initialPosition } from 'consts';
import { SimpleMarker } from 'components/Marker/Marker';

import {
  getCurrentMarkerBlogPost,
  getCurrentMarkerImages,
  getMarker,
} from 'components/Marker/support';

import './Map.scss';

const Map = () => {
  const {
    valueProps: {
      markers,
      map: currentMap,
      markerImages,
      blogPostsUris,
      currentTile,
      markerTypes,
    },
    setProps: { setMap },
  } = useContext(AppCtx);
  const places = markers.map((marker: MyMarker) =>
    getMarker(
      markerTypes,
      marker,
      new LatLng(marker.lat, marker.lng),
      currentMap,
      getCurrentMarkerImages(marker, markerImages),
      getCurrentMarkerBlogPost(marker, blogPostsUris)
    )
  );

  useEffect(() => {
    let circle: L.Circle;
    currentMap?.locate().on('locationfound', function (e) {
      // setPosition(e.latlng);
      currentMap.flyTo(e.latlng, currentMap.getZoom());
      const radius = e.accuracy;
      circle = L.circle(e.latlng, { radius: 10, color: 'sandybrown' });
      circle.addTo(currentMap);
    });
  }, [currentMap]);

  return (
    <MapContainer
      style={{ minHeight: '65vh', border: '4px solid sandybrown' }}
      center={initialPosition}
      zoom={10}
      whenCreated={setMap}
    >
      <LayersControl position='topright'>
        {currentTile === 'Схема' && (
          <>
            <LayersControl.BaseLayer checked name='Схема'>
              <TileLayer
                minZoom={2}
                maxZoom={20}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name='Prussia'>
              <TileLayer
                attribution='&copy; <a http="https://konigsland.ru" target="_blank">Восточная Пруссия</a>'
                minZoom={11}
                maxZoom={15}
                opacity={0.4}
                url='https://konigsland.online/tiles/{x}/{y}/{z}.jpeg'
              />
            </LayersControl.Overlay>
          </>
        )}
        {currentTile === 'Схема2' && (
          <>
            <LayersControl.BaseLayer checked name='Схема2'>
              <TileLayer
                minZoom={2}
                maxZoom={18}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}.png'
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name='Prussia'>
              <TileLayer
                attribution='&copy; <a http="https://konigsland.ru" target="_blank">Восточная Пруссия</a>'
                minZoom={11}
                maxZoom={15}
                opacity={0.6}
                url='https://konigsland.online/tiles/{x}/{y}/{z}.jpeg'
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay name='Схема'>
              <TileLayer
                minZoom={2}
                maxZoom={20}
                opacity={0.5}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
            </LayersControl.Overlay>
          </>
        )}
        {currentTile === 'Пруссия' && (
          <>
            <LayersControl.BaseLayer checked name='Prussia'>
              <TileLayer
                attribution='&copy; <a http="https://konigsland.ru" target="_blank">Восточная Пруссия</a>'
                minZoom={11}
                maxZoom={15}
                url='https://konigsland.online/tiles/{x}/{y}/{z}.jpeg'
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name='Схема'>
              <TileLayer
                minZoom={2}
                maxZoom={20}
                opacity={0.4}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
            </LayersControl.Overlay>
          </>
        )}
        {currentTile === 'ArcGIS' && (
          <>
            <LayersControl.BaseLayer checked name='ArcGIS'>
              <TileLayer
                minZoom={2}
                maxZoom={17}
                attribution='&copy; <a href=http://www.arcgis.com/>Esri</a>'
                url='http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name='Prussia'>
              <TileLayer
                attribution='&copy; <a http="https://konigsland.ru" target="_blank">Восточная Пруссия</a>'
                minZoom={11}
                maxZoom={15}
                opacity={0.4}
                url='https://konigsland.online/tiles/{x}/{y}/{z}.jpeg'
              />
            </LayersControl.Overlay>
          </>
        )}
        {currentTile === 'Google' && (
          <>
            <LayersControl.BaseLayer checked name='Google'>
              <TileLayer
                attribution='&copy; KONIGSLAND'
                minZoom={2}
                maxZoom={20}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                url='http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name='Prussia'>
              <TileLayer
                attribution='&copy; <a http="https://konigsland.ru" target="_blank">Восточная Пруссия</a>'
                minZoom={11}
                maxZoom={15}
                opacity={0.5}
                url='https://konigsland.online/tiles/{x}/{y}/{z}.jpeg'
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay name='Схема'>
              <TileLayer
                minZoom={2}
                maxZoom={20}
                opacity={0.4}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
            </LayersControl.Overlay>
          </>
        )}
      </LayersControl>
      <SimpleMarker />
      {places}
    </MapContainer>
  );
};

export default Map;
