import React from "react";
import { guestUser, initialPosition } from "consts";
import { dummyFunc } from "utils";
import * as L from 'leaflet';
import { LatLng } from "leaflet";

export interface MarkerType {
  id: number;
  type: string;
  icon: string;
  marker: MyMarker;
}


export interface MyMarker {
  id: number;
  lat: number;
  lng: number;
  is_moderated: boolean;
  description: string;
  marker_type: number;
  openPopup: () => {};
}

type AppCtxType = {
  setProps: {
    setUserName: (userName: string) => void;
    setShowLogin: (show: boolean) => void;
    setShowRegister: (show: boolean) => void;
    setShowPlaceModal: (show: boolean) => void;
    setShowInfo: (show: boolean) => void;
    setCurrentPosition: (position: LatLng) => void;
    setMarkers: (markers: Array<MyMarker>) => void;
    setMap: (map: L.Map|undefined) => void;
    setMarkerImages: (markerImages: object) => void;
    setBlogPostsUris: (markerImages: object) => void;
    setMarkerTypes: (markerTypes: Array<MarkerType>) => void;
    setCurrentTile: (currentTile: string) => void;
    setBalance: (balance: number) => void;
  };
  valueProps: {
    userName: string;
    balance: number;
    showLogin: boolean;
    showRegister: boolean;
    showPlaceModal: boolean;
    showInfo: boolean;
    currentPosition: LatLng;
    markers: Array<MyMarker>;
    map: L.Map|undefined;
    markerImages: object|undefined;
    blogPostsUris: object|undefined;
    markerTypes: Array<MarkerType>;
    currentTile: string;
  };
};

export const AppCtx = React.createContext<AppCtxType>({
  setProps: {
    setUserName: dummyFunc,
    setBalance: dummyFunc,
    setShowLogin: dummyFunc,
    setShowRegister: dummyFunc,
    setShowPlaceModal: dummyFunc,
    setShowInfo: dummyFunc,
    setCurrentPosition: dummyFunc,
    setMarkers: dummyFunc,
    setMap: dummyFunc,
    setMarkerImages: dummyFunc,
    setBlogPostsUris: dummyFunc,
    setMarkerTypes: dummyFunc,
    setCurrentTile: dummyFunc,
  },
  valueProps: {
    userName: guestUser,
    balance: 0,
    showLogin: true,
    showRegister: false,
    showPlaceModal: false,
    showInfo: false,
    currentPosition: initialPosition,
    markers: [],
    map: undefined,
    markerImages: {1: {}},
    blogPostsUris: {1: {}},
    markerTypes: [],
    currentTile: ''
  },
});
