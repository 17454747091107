import React, { useContext, useState } from 'react';
import { LatLng, LeafletMouseEvent } from 'leaflet';
import { AppCtx, MarkerType } from 'context/appContext';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import { sendCreateMarkerRequest } from 'api';
import { isGuest } from 'utils';
import { markerIcon } from 'components/Marker/icons';
import { mouseMoveEvents } from 'components/Marker/events';
import { getFileUploadingSection } from 'components/Marker/support';
import './Marker.scss';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { initialPosition } from 'consts';

export const SimpleMarker = () => {
  const [position, setPosition] = useState<LatLng | null>(null);
  const [newMarkerType, setNewMarkerType] = useState<MarkerType>();
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const {
    valueProps: {
      userName,
      markers,
      map: currentMap,
      markerTypes,
      currentTile,
    },
    setProps: { setCurrentPosition, setMarkers },
  } = useContext(AppCtx);
  const map = useMapEvents({
    click(e: LeafletMouseEvent) {
      const { latlng } = e;
      map.flyTo(latlng, map.getZoom());
      setPosition(latlng);
      setCurrentPosition(latlng);
    },
    mouseup(e: LeafletMouseEvent) {
      const prussiaOverflow =
        e.latlng.lat > 54.24016463610073 &&
        e.latlng.lat < 55.3195788931395 &&
        e.latlng.lng > 19.84062194824219 &&
        e.latlng.lng < 23.003311157226562;
      !prussiaOverflow &&
        currentTile === 'Пруссия' &&
        map.flyTo(initialPosition, map.getZoom());
    },
  });

  const saveMarker = () =>
    position &&
    newMarkerType &&
    sendCreateMarkerRequest(
      newMarkerType,
      position,
      description,
      markers,
      files,
      setMarkers
    );

  const guest = isGuest(userName);

  const placeholder = guest
    ? 'Только зарегистрированные пользователи могут добавлять локации'
    : 'Укажите описание для новой позиции';

  const showMarkerIcon = (event: SelectChangeEvent<string>) => {
    if (markerTypes) {
      const type = markerTypes.find((mt) => mt.type === event.target.value);
      type && setNewMarkerType(type);
    }
  };
  return (
    position && (
      <Marker
        position={position}
        icon={markerIcon}
        eventHandlers={{
          click: (e: LeafletMouseEvent) => {
            position &&
              currentMap &&
              currentMap.flyTo(
                { lat: position.lat + 0.005, lng: position.lng + 0.0001 },
                15
              );
          },
          ...mouseMoveEvents,
        }}
      >
        <Popup>
          <div className='type-container'>
            {newMarkerType && (
              <div dangerouslySetInnerHTML={{ __html: newMarkerType.icon }} />
            )}
            <div className='marker-type'>
              <label>
                <FormControl fullWidth>
                  <InputLabel id='marker-type-input'>Тип маркера</InputLabel>
                  <Select
                    labelId='marker-type-input'
                    id='marker-types'
                    name='marker-types[]'
                    value={newMarkerType?.type}
                    label='marker-type'
                    onChange={(event) => showMarkerIcon(event)}
                  >
                    {markerTypes.map((mt: MarkerType) => (
                      <MenuItem key={mt.id} value={mt.type}>
                        {mt.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </label>
            </div>
          </div>
          <textarea
            onChange={({ target: { value: newDesc } }) =>
              setDescription(newDesc)
            }
            disabled={guest}
            aria-multiline='true'
            placeholder={placeholder}
            value={description}
          />
          {getFileUploadingSection(files, setFiles)}
          <br />
          <Button
            size='small'
            variant='contained'
            color='success'
            onClick={saveMarker}
            disabled={guest}
          >
            Сохранить
          </Button>
        </Popup>
      </Marker>
    )
  );
};
